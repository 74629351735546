import { React, useState } from 'react';
import './App.css';

import Section from './Components/Section/Section';
import Header from './Components/Header/Header';



function App() {
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);
  return (
    <div className="App-container">
      {/* <Header isOpaque={isSectionExpanded} /> */}

      <Section section={"Web"} setIsSectionExpanded={setIsSectionExpanded} />
      <Section section={"Applications"} setIsSectionExpanded={setIsSectionExpanded} />
      <Section section={"Automation"} setIsSectionExpanded={setIsSectionExpanded} />
      <Section section={"Contact"} setIsSectionExpanded={setIsSectionExpanded} />
    </div>
  );
}

export default App;
