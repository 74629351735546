import React, { useState } from 'react';
import './Section.css';



function Section(props) {
    let title = props.section;
    let icon;
    let content;

    const [contentVisibility, toggleContentVisibility] = useState(false);

    switch (title) {
        case ("Web"):
            icon = require('../../../Assets/img/Icons/light/monitor.svg').default;
            content =
            `
            Web Design
            `
            break;
        case ("Applications"):
            icon = require('../../../Assets/img/Icons/light/pie-chart.svg').default;
            content = 
            `
            Application Development
            `
            break;
        case ("Automation"):
            icon = require('../../../Assets/img/Icons/light/feather.svg').default;
            content = 
            `
            Workflow Automation
            `
            break;
        case ("Contact"):
            icon = require('../../../Assets/img/Icons/light/phone.svg').default;

            let PhoneIcon = 
            content = 
            `
                Contact Information:
                ${<img src={icon} /> }
            `
            break;
        default:
            icon = require('../../../Assets/img/Icons/light/monitor.svg').default;
    }

    let toggleContent = (b) => {
        toggleContentVisibility(!contentVisibility);
        props.setIsSectionExpanded(b);
    };

    return (
        <div className={"Section " + title} onMouseEnter={() => toggleContent(true)} onMouseLeave={() => toggleContent(false)}>
            <div className={`Section__Title ${contentVisibility}`}>
                {title}
            </div>
            <div className={`Section__Icon ${contentVisibility}`}>
                <img src={icon} className={`Section__Content--icon ${contentVisibility}`} />
            </div>
            <div className={`Section__Content ${contentVisibility}`}>
                {content}
            </div>
        </div>
    )
}

export default Section